<template>
  <el-dialog v-model="showDia" title="结算页" @open="openDia" :before-close="closeOrder" class="confirm-dialog"
             align-center width="1200"
             :close-on-click-modal="false" destroy-on-close>
    <div class="content">
      <h2 class="sub-title">订单信息</h2>
      <div class="buy-info" style="margin-top: 25px;">
        <form-item title="企业名称" type="2" :value="companyName"></form-item>
        <template v-if="formData.name">
          <form-item title="联系人" type="2" :value="formData.name"></form-item>
          <form-item title="联系电话" type="2" :value="formData.mobile"></form-item>
          <form-item title="收货地址" type="2" :value="formData.address"></form-item>
        </template>
      </div>
      <template v-if="orderInfo.list">
        <goods-list :list="orderInfo.list" :info="orderInfo.map" class="mt_32"></goods-list>
      </template>
      <div class="total dp_f mt_60">
        <div class="sub-text">支持7天无理由退货退款（<span class="red">*</span>退货退款要求：购买商品无损坏、包装完好，不影响二次销售）
        </div>
        <div class="price" v-if="orderInfo.map">
          <div class="text">应付总额</div>
          <div class="num">￥{{ $filters.toYuan(orderInfo?.map?.orderAmount, '商城') }}</div>
          <div class="sun-num" v-if="orderInfo?.map?.discountAmount !== 0">
            (优惠码省<span>￥{{ $filters.toYuan(orderInfo?.map?.discountAmount, '商城') }}</span>)
          </div>
        </div>
      </div>
      <pay class="mt_60" @closePay="closePay" :order-sn="orderInfo?.orderSn" ref="payRef"></pay>
    </div>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, ref, toRefs} from "vue";
import FormItem from "@views/shop/components/formItem.vue";
import GoodsList from './components/list.vue'
import Pay from './components/pay.vue'
import {ElMessageBox} from "element-plus";
import {getUserInfo} from "@utils/tool/getUserInfo";
import useOrder from "@utils/tool/shop/useOrder";
import {useEventListener} from "@utils/tool/event";

const showPay = ref(false)

const {closeOrderCreate, resetCloseOrderCreate} = useOrder();
useEventListener(window, 'beforeunload', () => {
  closeOrderCreate(orderInfo.value)
})
const payRef = ref(null)
const {companyName} = getUserInfo()
const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => {
    }
  },
  total: {
    type: Object,
    default: () => {
    }
  },
  formData: {
    type: Object,
    default: () => {
    }
  }
})
const {orderInfo, total, formData} = toRefs(props)
const emit = defineEmits(['closeConfirm'])
const showDia = ref(false)
const {proxy} = getCurrentInstance();
const totalPrice = computed(() => {
  try {
    let total = 0
    if (orderInfo.value?.list.length) {
      orderInfo.value.list.forEach(item => {
        total += item.price
      })
    }
    if (orderInfo.value?.map) {
      total += orderInfo.value.map.nowPrice
    }
    return proxy.$filters.toYuan(total, '元')
  } catch (e) {
    closeOrderCreate(orderInfo.value)
  }
})
const openDia = () => {
  resetCloseOrderCreate()
  showPay.value = true
}
const closeOrder = () => {
  ElMessageBox.confirm('订单未完成，是否离开？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    customClass: "zh-message-box shop",
  }).then(() => {
    payRef.value.closePay()
    closePay()
  }).catch(() => {
    // do nothing
  });
}

const closePay = () => {
  showPay.value = false
  showDia.value = false
  closeOrderCreate(orderInfo.value)
  emit('closeConfirm')
}
defineExpose({
  showDia
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "confirmDialog"
});
</script>

<style lang="scss" scoped>
.confirm-dialog {
  .content {
    padding: 6px 40px;
    max-height: 80vh;
    overflow: auto;
    .sub-title {
      font-size: 20px;
      font-weight: 600;
      color: $shopFsColor;
    }

    .total {
      .sub-text {
        font-size: 14px;
        color: $shopFsColor2;
      }

      .price {
        display: flex;
        align-items: center;

        div {
          line-height: 1;
        }

        .text {
          font-size: 24px;
          color: $shopFsColor;
        }

        .num {
          font-size: 30px;
          margin-left: 8px;
          font-weight: 600;
          color: $mainColor;
        }

        .sun-num {
          color: $shopFsColor2;
          font-size: 18px;
          position: relative;
          top: 4px;

          span {
            color: $shopFsColor;
          }
        }
      }
    }
  }
}
</style>
