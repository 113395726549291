import request from '../http'
import {managerUrl} from '../api'

//微信支付
export function weChatPayUrl(data) {
    return request({
        url: managerUrl + "/carton/pay/wechat/native/pay",
        method: 'post',
        data
    })
}

//支付宝支付
export function aliPayUrl(data) {
    return request({
        url: managerUrl + "/carton/pay/ali/native/pay",
        method: 'post',
        data
    })
}

//支付状态查询
export function payStatus(data) {
    return request({
        url: managerUrl + "/carton/pay/check",
        method: 'post',
        data
    })
}