import {ref} from 'vue';
import {closeShopOrder} from '@/utils/api/shop'

export default function () {
    const isClose = ref(false)
    const orderCloseTimer = process.env.NODE_ENV === 'line' ? 300 : 30
    const closeOrderCreate = (orderInfo) => {
        if (isClose.value) {
            //已经取消监听过了
            return
        }
        closeShopOrder({
            orderSn: orderInfo.orderSn || ''
        }).then(() => {
            isClose.value = true
        })
    }
    //重新刷新监听flag
    const resetCloseOrderCreate = () => {
        isClose.value = false
    }

    return {
        closeOrderCreate,
        resetCloseOrderCreate,
        orderCloseTimer
    }
}