<template>
  <div class="phone-pay dp_f cur_p">
    <div class="ali" @click="pay(1)" :class="payWay === 1 ? 'active':''">
      <img src="@img/shop/index/ali-pay.png" v-if="payWay === 1" alt="位元灵感"/>
      <img src="@img/shop/index/ali-pay2.png" v-else alt="位元灵感"/>
      <div class="text">支付宝支付</div>
    </div>
    <div class="wechat" @click="pay(2)" :class="payWay === 2 ? 'active':''">
      <img src="@img/shop/index/wechat-pay.png" v-if="payWay === 2" alt="位元灵感"/>
      <img src="@img/shop/index/wechat-pay2.png" v-else alt="位元灵感"/>
      <div class="text">微信支付</div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, onUnmounted, ref, watch} from "vue";
import {aliPayUrl, payStatus, weChatPayUrl} from "@apis/pay.js";
import {ElLoading} from "element-plus";

let checkTimer = null;
let goTimer = null
const props = defineProps({
  orderSn: {
    type: String,
    default: ''
  },
})
const emit = defineEmits(['getPay'])
const {proxy} = getCurrentInstance();
const payWay = ref(0)
const pay = (type) => {
  if (payWay.value === type) return
  let api = type === 2 ? weChatPayUrl : aliPayUrl
  api({orderSn: props.orderSn}).then(res => {
    if (res.code === 0) {
        emit('getPay', {type, url: res.data})
        payWay.value = type
        checkPay()
      } else {
        proxy.$message.error(res.msg)
      }
  })
  //type 1 支付宝 2 微信
  // if (type === 2) {
  //   weChatPayUrl({orderSn: props.orderSn}).then(res => {
  //     if (res.code === 0) {
  //       emit('getPay', {type, url: res.data})
  //       payWay.value = type
  //       checkPay()
  //     } else {
  //       proxy.$message.error(res.msg)
  //     }
  //   })
  // } else {
  //   emit('getPay', {type} )
  //   payWay.value = type
  //   // proxy.$message.error('暂不支持支付宝支付')
  // }
}
const checkPay = () => {
  if (checkTimer) clearTimeout(checkTimer)
  checkTimer = setTimeout(() => {
    payStatus({orderSn: props.orderSn}).then(res => {
      if(res.code !== 0) return
      if (res.data === true) {
        let time = 3;
        const loading = ElLoading.service({
          lock: true,
          text: `支付成功,${time}s后跳转`,
          background: 'rgba(255, 255, 255, 0.5)',
        })
        goTimer = setInterval(() => {
          if (time === 0) {
            loading.close()
            clearInterval(goTimer)
            proxy.$router.push({path: '/shop/person/order', replace: true})
          } else {
            time--
            loading.setText(`支付成功,${time}s后跳转`)
          }
        }, 1000)
      } else {
        checkPay()
      }
    })
  }, 1000)
}
onUnmounted(() => {
  clearTimeout(checkTimer)
})
watch(() => props.orderSn, (val) => {
  if (val) {
    pay(1)
  }
}, {
  immediate: true
})
const closePay = () => {
  payWay.value = 0
  clearTimeout(checkTimer)
}
defineExpose({
  closePay
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "phonePay",
});
</script>

<style lang="scss" scoped>
.phone-pay {
  height: 48px;
  border-radius: 8px;
  width: 360px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 180px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .ali {
    background-color: #fff;
    color: #466AFE;
    border-radius: 8px 0 0 8px;
    border: 1px solid $shopBorderColor;
    border-right: none;

    &.active {
      background-color: #466AFE;
      color: #fff;
      border: none;
    }
  }
  .wechat {
    background-color: #fff;
    color: #48CB9F;
    border-radius: 0 8px 8px 0;
    border: 1px solid $shopBorderColor;
    border-left: none;

    &.active {
      background-color: #48CB9F;
      color: #fff;
      border: none;
    }
  }
}
</style>
