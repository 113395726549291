<template>
  <div class="confirm-order container  dp_f">
    <div class="price">
      <div class="text">订单总额</div>
      <div class="num">￥{{ total.total }}</div>
      <div class="sun-num" v-if="total.diff !== '0'">(优惠码省<span>￥{{ total.diff }}</span>)</div>
    </div>
    <button class="wy-button primary confirm-btn" @click="emit('confirm')">{{ buttonStr ? buttonStr : '立即下单' }}
    </button>
  </div>
</template>

<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  total: {
    type: Object,
    default: () => {
    }
  },
  buttonStr: {
    type: String,
    default: ''
  }
})
const {total, buttonStr} = toRefs(props);
const emit = defineEmits(['confirm'])
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "confirmOrder",
});
</script>

<style lang="scss" scoped>
.confirm-order {
  background-color: $shopBgColor;

  .price {
    display: flex;
    align-items: center;

    div {
      line-height: 1;
    }

    .text {
      font-size: 30px;
      color: $shopFsColor;
      font-weight: 600;
    }

    .num {
      font-size: 34px;
      margin-left: 8px;
      color: $mainColor;
      font-weight: 600;
    }

    .sun-num {
      color: $shopFsColor2;
      font-size: 18px;
      position: relative;
      top: 4px;

      span {
        color: $shopFsColor;
      }
    }
  }

  .confirm-btn {
    width: 200px;
    height: 56px;
    line-height: 56px;
    font-size: 18px;
  }
}
</style>
