<template>
  <div class="concat-pic">
    <vue-qr  :logoScale="0.2" :logoMargin="6" :text="url" :size="size" :style="style" :correctLevel="3"
            :margin="0"></vue-qr>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, toRefs} from "vue";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

const props = defineProps({
  url: {
    type: String,
    default: ""
  },
  size: {
    type: Number,
    default: 180
  },
  type: {
    type: String,
    default: "wechat"
  }
})
const {url, size} = toRefs(props);
const {proxy} = getCurrentInstance();
const style = computed(() => {
  return {
    width: size.value + 'px',
    height: size.value + 'px'
  }
})
const logo = computed(() => {
  if (props.type === 'wechat') {
    return require('@img/shop/register/wechat-pay.png');
  } else {
    return require('@img/shop/register/ali-pay.png');
  }
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "payPic"
});
</script>

<style lang="scss" scoped>
.concat-pic {
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 6px;
  width: max-content;
}
</style>
