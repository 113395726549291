<template>
  <div class="pay-content dp_f">
    <div class="pay-way">
      <div class="left">
        <div class="title">对公转账</div>
        <public-pay class="mt_32"></public-pay>
        <div class="sub-desc mt_48">如需对公支付，请联系客服</div>
      </div>
      <div class="right">
        <div class="title">在线支付 <span class="recommend">推荐</span></div>
        <phone-pay ref="phonePayRef" class="mt_32" @get-pay="getPay" :order-sn="orderSn"></phone-pay>
        <div class="sub-desc mt_32" v-show="showPay">
          <CountNumber @end="closeDia" class="mb_8" :time="orderCloseTimer">后关闭订单！</CountNumber>
          <p>扫描右侧二维码支付</p>
        </div>
      </div>
    </div>
    <div class="tem">
      <div class="pay-img animate__fadeIn animate__animated" v-show="showPay">
        <pay-img :url="url" :type="type" :size="180" v-if="url && type === 'wechat'"></pay-img>
        <iframe class="iframe-ali" v-else-if="url" :src="url" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, ref, toRefs} from "vue";
import PublicPay from "@views/shop/order/components/confirmDialog/components/publicPay.vue";
import PhonePay from "@views/shop/order/components/confirmDialog/components/phonePay.vue";
import PayImg from "@views/shop/components/payimg.vue";
import {ElLoading} from 'element-plus';
import CountNumber from "@views/shop/components/countNumber/index.vue";
import useOrder from "@utils/tool/shop/useOrder";

const props = defineProps({
  orderSn: {
    type: String,
    default: '',
    required: false
  },
})
const {orderCloseTimer} = useOrder();
const phonePayRef = ref(null)
const {orderSn} = toRefs(props);
const emit = defineEmits(['closePay'])
const showPay = ref(false)
const {proxy} = getCurrentInstance();
const type = ref('')
const url = ref('')
const getPay = (obj) => {
  let str = obj.type === 2 ? 'wechat' : 'ali';
  if (type.value === str) return
  const loading = ElLoading.service({
    lock: true,
    text: `链接生成中`,
    background: 'rgba(255, 255, 255, 0.5)',
  })
  showPay.value = false
  type.value = str;
  url.value = obj.url;
  setTimeout(() => {
    loading.close()
    showPay.value = true
  }, 1000)
}
const closePay = () => {
  phonePayRef.value.closePay()
}

const closeDia = () => {
  closePay()
  emit('closePay')
}

defineExpose({
  closePay
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "pay",
});
</script>

<style lang="scss" scoped>
.tem {
  height: 204px;
}

.pay-img {
  display: flex;
  align-items: center;
  .iframe-ali {
    width: 192px;
    height: 195px;
    border-radius: 8px;
    padding: 6px;
    border: 1px solid #c4c4c4;
    overflow: hidden;
  }
  .right-img {
    width: 78px;
    height: 16px;
    margin-right: 20px;
  }
}
.pay-way {
  display: flex;
  .left {
    border-right: 1px dashed #c4c4c4;
  }

  .right {
    padding-left: 92px;
    box-sizing: border-box;
  }

  > div {
    width: 375px;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: $shopFsColor;
      display: flex;
      align-items: center;
    }

    .recommend {
      margin-left: 6px;
      display: inline-block;
      width: 56px;
      height: 24px;
      background: #FF910A;
      border-radius: 4px;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      color: #fff;
      font-weight: normal;
    }
  }
}
</style>
