<template>
  <p class="time-out" v-bind="$attrs">{{ convertTime(time) }}
    <slot></slot>
  </p>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import {convertTime} from '@/utils/tool'
import {useInterval} from "@utils/tool/useTimer";

const props = defineProps({
  time: {
    type: Number,
    default: 60
  },
})
const emit = defineEmits(['end'])
const time = ref(props.time)
const {setTimer, clearTimer} = useInterval()
onMounted(() => {
  clearTimer()
  setTimer(() => {
    if (time.value <= 0) {
      clearTimer()
      emit('end')
      return
    }
    time.value--
  }, 1000)
})
onUnmounted(() => {
  clearTimer()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "countNumber",
});
</script>

<style lang="scss" scoped>
.time-out {
  color: red;
}
</style>
