<template>
  <div class="phone-pay cur_p" v-bind="$attrs" @click="handleClick">对公转账</div>
  <concat-d-ia v-model="showDia" value="如需对公转账，请联系客服"/>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import ConcatDIa from "@views/shop/components/concatDIa/index.vue";

const {proxy} = getCurrentInstance();

const showDia = ref(false)
const handleClick = () => {
  showDia.value = true
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "publicPay",
});
</script>

<style lang="scss" scoped>
.phone-pay {
  width: 160px;
  height: 48px;
  border: 1px solid #FF6669;
  border-radius: 8px;
  text-align: center;
  line-height: 48px;
  color: #ff6669;
}


</style>
