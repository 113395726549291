<template>
  <div class="order-form-item dp_f" v-bind="attrs">
    <div class="form-item-title" :class="type === '2' ? 'sub': ''"><span class="red" v-if="required">*</span> {{
        title
      }}
    </div>
    <template v-if="value">
      <div class="form-item-value">{{ value }}</div>
    </template>
    <slot v-else></slot>
  </div>
</template>

<script setup>
import {getCurrentInstance, toRefs, useAttrs} from "vue";

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  value: {
    type: String,
    default: ""
  },
  required:{
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: ""
  }
})
const {title, value, required, type} = toRefs(props);
const attrs = useAttrs()
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "formItem",
});
</script>

<style lang="scss" scoped>
.order-form-item {
  color: $shopFsColor;
  min-height: 56px;
  align-items: flex-start !important;
  flex-wrap: wrap;
  .form-item-title {
    font-size: 18px;
    width: max-content;
    margin-right: 100px;
    line-height: 2;
    &.sub {
      color: $shopFsColor2;
    }
  }
  .form-item-value {
    font-size: 18px;
    flex: 1;
    word-break: break-word;
    text-align: right;
    line-height: 2;
  }
}
</style>
